import {useState, useEffect, useRef} from 'react'

const StandFirst = (props) => {

    const { pageData } = props;

    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);
    const [isInView, setIsInView] = useState(false);
    const [done, setDone] = useState(false);
    const elementRef = useRef(null);
    const speed = 10;


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }

    };
    return;
  }, []);
  
    useEffect(() => {
      if (index < pageData.standFirst.text.length && isInView) {
        const timeoutId = setTimeout(() => {
          setDisplayedText(displayedText + pageData.standFirst.text.charAt(index));
          setIndex(index + 1);
        }, speed);
  
        return () => clearTimeout(timeoutId);
      } else if (index === pageData.standFirst.text.length) {
        setTimeout(()=>{
          setDone(true)
        }, 800)
        
      }
    }, [index, pageData.standFirst.text, displayedText, speed, isInView]);


  return (
    <section className='stand-first__wrapper' ref={elementRef}>
       <h3 className='stand-first__text-wrapper alt-font'>{displayedText}
       <span className={`stand-first__cursor ${done?'disabled':''}`}>{" |"}</span></h3> 
    </section>
  )
}

export default StandFirst