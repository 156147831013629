import Paragraph from "./Paragraph";

const LeftRightText = (props) => {
  const { data } = props;

  return (
    <section className={`left-right__wrapper text-only`}>
      <div className="left-right__half">
        <div
          className={`left-right__text-wrapper ${data.left.type} text-only`}
        >
          {data.left.text.map((text, index) => {
            return <Paragraph key={index} data={text[0]} />;
          })}
        </div>
      </div>
      <div className="left-right__half right">
          <div
            className={`left-right__text-wrapper ${data.right.type} text-only`}
          >
            {data.right.text.map((text, index) => {
              return <Paragraph key={index} data={text[0]} />;
            })}
          </div>
      </div>
    </section>
  );
};

export default LeftRightText;
