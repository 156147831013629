import { useEffect, useState } from "react";

const ReadTime = (props) => {

  const [readTime, setReadTime] = useState(10);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const checkProgress = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (documentHeight - windowHeight > 0) {
        const currentScrollPercentage =
          (scrollTop / (documentHeight - windowHeight)) * 100;
        setProgress(currentScrollPercentage);
      }

      const totalScrollableDistance = documentHeight - windowHeight;
      const remainingScrollDistance = totalScrollableDistance - scrollTop;

      if (totalScrollableDistance > 0) {
        const remainingPercentage = Math.floor(
          (remainingScrollDistance / totalScrollableDistance) * 100 * 0.05 + 1
        );
        setReadTime(remainingPercentage);
      }
    };

    checkProgress();
    window.addEventListener("scroll", checkProgress);
  }, []);

    return (
      <aside className="read-time__wrapper">
          <div className="read-time__progress" style={{ width: `${progress}%` }} >
            <div className="read-time__number">{readTime} min{readTime > 1 || readTime === 0 ? "s" : ""}</div>
          </div>
      </aside>
    );
};

export default ReadTime;
