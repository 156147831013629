import React, { useEffect, useState } from "react";
import ReactEmoji from "./ReactEmoji";

const TextMessage = (props) => {
  const { message, index, messageIndex } = props;

  const [messageActive, setMessageActive] = useState(false);
  const [emojiActive, setEmojiActive] = useState(false);

  const [reactActive, setReactActive] = useState(false);
  const [clickable, setClickable] = useState(true);
  const [focusActive, setFocusActive] = useState(false);

  useEffect(() => {
    if (index === messageIndex) {
      setMessageActive(true);
    }
  }, [messageIndex]);

  const handleReact = () => {
    setReactActive(true);
    setClickable(false);
    setTimeout(() => {
      setReactActive(false);
    }, 2000);
    setTimeout(() => {
      setClickable(true);
    }, 4000);
  };

  const openFocus = () => {
    setFocusActive(true);
    handleReact();
  };
  const closeFocus = () => {
    setFocusActive(false);
  };

  return (
    <>
      <div
        className={`texts__message-wrapper 
        ${messageActive ? "active" : ""} 
        ${message.side}`}
      >
        <div 
        className={`texts__message-inner ${message.side} ${clickable ? "clickable" : ""}`}
        onClick={()=>{
          setEmojiActive(true)
          handleReact();
        }}
        >
          {message.type === "text" ? (
            message.text
          ) : (
            <button
              onClick={openFocus}
              className={`
              texts__image-button
              ${clickable ? "active" : ""}`}
            >
              <img
                src={`./images/${message.source}`}
                className={`texts__image `}
                alt="Charli XCX Forever"
              />
            </button>
          )}
          {message.emoji && emojiActive ? (
            <button
              onClick={handleReact}
              className={`texts__emoji ${message.side} ${
                clickable ? "active" : ""
              }`}
            >
              {message.emoji}
            </button>
          ) : (
            ""
          )}
          {index === 0 || index === 1 ? (
            <div className="texts__sender">{message.from}:</div>
          ) : (
            ""
          )}
        </div>
      </div>

      {message.emoji ? (
        <div className={`texts__react-wrapper ${reactActive ? "active" : ""}`}>
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
          <ReactEmoji active={reactActive} emoji={message.emoji} />
        </div>
      ) : (
        ""
      )}

      {message.type === "image" ? (
        <button
          className={`texts__focus-wrapper ${focusActive ? "active" : ""}`}
          onClick={closeFocus}
        >
          <div
            className="texts__focus-image"
            style={{ backgroundImage: `url(./images/${message.source})` }}
          ></div>
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default TextMessage;
