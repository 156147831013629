import Paragraph from "./Paragraph";

const LeftRight = (props) => {
  const { data } = props;

  return (
    <section className={`left-right__wrapper ${data.textOnly? 'text-only':''}`}>

      {data.background ? (
        data.background.variant === "image" ? (
          <div
            className="left-right__background-image"
            style={{
              backgroundImage: `url(./images/${data.background.source})`,
            }}
          />
        ) : (
          <div className="left-right__background-video-wrapper">
            <video
              className="left-right__background-video"
              src={`./videos/${data.background.source}`}
              autoPlay
              loop
              muted
              playsInline
              poster={`url(./images/${data.background.poster})`}
            />
          </div>
        )
        ):''}

      <div className="left-right__half">
        {data.left.type === "image" ? (
          <div className="left-right__image-wrapper">
            <img
              className={"left-right__image"}
              src={`./images/${data.left.source}`}
              alt="Charli XCX Forever"
            />
          </div>
        ) : (
          <div className={`left-right__text-wrapper ${data.left.type} ${data.textOnly? 'text-only':''}`}>
            {data.left.text.map((text, index) => {
              return <Paragraph key={index} data={text[0]} />
              
            })}
          </div>
        )}
      </div>
      <div className="left-right__half right">
        {data.right.type === "image" ? (
          <div className="left-right__image-wrapper">
            <img
              className={"left-right__image"}
              src={`./images/${data.right.source}`}
              alt="Charli XCX Forever"
            />
          </div>
        ) : (
          <div className={`left-right__text-wrapper ${data.right.type} ${data.textOnly? 'text-only':''}`}>
            {data.right.text.map((text, index) => {
              return <Paragraph key={index} data={text[0]} />;
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default LeftRight;
