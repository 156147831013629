const Data = {
  info: {},
  cover: {
    background: {
      variant: "image",
      source: "Charli_01.jpg",
    },
    source: "Charli_cover-3.mp4",
    poster: "Charli_03.jpg",
  },
  content: [
    // [[link:https://example.com], [text: EXAMPLE]]
    {
      type: "PullQuote",
      cardSource: "Charli_01.jpg",
      background: {
        variant: "video",
        source: "360_01.mp4",
        poster: "Charli_01.jpg",
      },
      text: `As BRAT continues to paint the world acid green, Katie Thomas spoke to the enduring pop icon about the most dominant dance floor smash of 2024.`,
      credit: "",
      textColor: "#000000",
      backgroundColor: "var(--charli-green)",
    },

    {
      type: "LeftRight",
      background: false,
      left: {
        type: "image",
        source: "Charli_03.png",
      },
      right: {
        type: "small-text",
        text: [
          [
            `"There's <nothing> worse than a protective DJ at a house party trying to play their one obscure song," Charli xcx laughed from the comfort of a big hotel bed. "The thing that's good about a party is when people feel like they can be messy. That's when it's <really> good."`,
          ],
          [
            `The British artist has zero time for po-faced aux hogging. In fact, this rejection of musical purism has become a central tenet of the pop outlier's career. Since her breakthrough in 2013, she has puckishly shapeshifted through various styles and moods, managing to balance chart success with a persistent experimental streak. Her avant-pop has always been rebellious, deliberately subverting what is expected of her or her sound. She has relished in the pomp of mainstream electro house, taken on snarlish electro-punk and bloomed in the liminal spaces of hyperpop—the cultural phenomenon that shook the foundations of electronic music in the 2010s.`,
          ],
          [
            `The main thread running through it all has been dance music. "I'm in a unique position where I genuinely did come from the club scene," she said when we met in Barcelona for a second chat. "It's had such a huge impact on me and my creativity." The most recent manifestation of Charli's rave inclinations has been impossible to miss: *[[link:https://ra.co/reviews/36119], [text: BRAT]]*, her sixth studio album, which has painted the world bright green and transported everyone to the heady chaos of the dance floor. This LP, more than ever, confirms that Charli xcx the pop star has always been Charli the club kid.`,
          ],
        ],
      },
    },
    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "large-text",
        text: [
          [
            `"I had to make this record, and I had to root it in [dance music] because it's so intrinsic to me," she said, before grinning: "There aren't many people in my position who could do it like I'm doing it." `,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [
          [
            `Every day since <BRAT>'s release on June 7th has been Charli-coded, acid-green pandemonium: millions who never properly acknowledged Charli before have now seen the green light, while the stans—known as Charli's Angels—are stanning to feverish new heights. At [[link:https://ra.co/features/4345], [text: Glastonbury]], thousands queued for hours to catch a glimpse of PARTYGIRL (the name for her DJ sets) at Silver Hayes, one of the festival's largest dance music areas. Her performance was so highly anticipated that a <BRAT>-green notice flashed on-screen after Dua Lipa's headline show, warning festivalgoers to avoid making the trip—they simply wouldn't get in. `,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },
    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "large-text",
        text: [[`It is, as Charli coined it, "<BRAT> girl summer."`]],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    {
      type: "PullQuote",
      cardSource: "Charli_01.jpg",
      background: {
        variant: "video",
        source: "VD_01.mp4",
        poster: "Charli_01.jpg",
      },
      text: "Charli xcx the pop star has always been Charli the club kid.",
      credit: "",
      textColor: "#FFFFFF",
      backgroundColor: "transparent",
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `<BRAT> is her first project explicitly marketed as a "club album," inspired by the nu rave movement of the '00s and the warehouse parties that shaped her youth. Its cover art—a simple, lurid green square with the title in plain text—shoots the fluorescent shock of '90s rave through the prism of memes. The text-only approach was first met with [[link:https://www.instagram.com/p/C76qooMJiDb], [text: hesitation]] by Charli's creative director, Imogene Strauss, but as soon as she saw a mock-up, it clicked. `,
          ],
        ],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "large-text",
        text: [
          [
            `"I'm interested in this idea of anti-aesthetic, anti-trend, anti-vibe," Charli said. "What is deemed aesthetically pleasing in culture, and how can I completely subvert that?"`,
          ],
        ],
      },
    },

    //
    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `Each move on the <BRAT> chessboard has been executed with total consideration. In the early stages of the album's rollout, Charli curated the most subscribed show in Boiler Room's history, caused a roadblock when she appeared at New York's The Lot Radio and sold out London venue HERE at Outernet in minutes. "Everything has taken the same amount of time as the music," Charli said. "That's why people who are watching this campaign closely are responding to it." Brands are [[link:https://www.thedrum.com/news/2024/07/02/charli-xcx-inspires-brat-tastic-campaign-field-roast-s-plant-based-bratwurst], [text: co-opting]] <BRAT> green to appeal to consumers, and the Green Party of England and Wales even [[link:https://x.com/TheGreenParty/status/1803850409523458092], [text: reworked]] the album cover ahead of the general election. "The industry moves so hard and fast now," Charli added. "If you put [in] thought, let your creatives breathe and mine out every single idea, the campaign becomes bulletproof."`,
          ],
          [
            `The masterplan worked: <BRAT> is a smash hit. It received rave reviews from <Resident Advisor>, *[[link:https://pitchfork.com/reviews/albums/charli-xcx-brat/], [text: Pitchfork]]* and the *[[link:https://www.theguardian.com/music/article/2024/jun/06/charli-xcx-brat-review-insecurity-obliterating-anthems-by-pops-most-human-superstar], [text: Guardian]]*; amassed over 300 million streams on Spotify; and, at the time of writing, even occupies the #16 slot on Metacritic's best albums of all time. <BRAT> debuted at #2 on the Official UK Charts (escaping the top spot only due to Taylor Swift's eyebrow-raising, region-specific reissue of her latest album) and #3 on the Billboard 200, eclipsing 2022's #7 slot for <CRASH>, Charli's fifth album. From rollout to release, <BRAT> is a triumph that never deviated from being <exactly> what Charli wanted it to be: a brash, cheeky and confessional body of work steeped in club culture. `,
          ],
        ],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [
          [
            `Fittingly, when I first spoke to Charli, she was still in bed after partying into the early hours with Justice in Cannes. Her hair was tied back in a low pony, faint smudges of last night's make-up around her eyes. She was hungover, she confessed, but her sharp wit and thoughtful nature showed no symptoms of the night before. Charli xcx is, after all, a professional party girl.`,
          ],
          [
            `Charli, who is 31, has been releasing records for more than half her life. Raised in Essex, she grew up in the era of LimeWire and the dawn of mass peer-to-peer file sharing. Remember the abrasive sounds of dial-up internet, or the fear of downloading a virus-ridden torrent to your family computer? Charli definitely does. Accelerated by the then-nascent World Wide Web, curious young music fans suddenly had an infinite pool of sounds at their fingertips for free, no longer trapped by genre limitations. As budding fans began to express themselves online through omnivorous musical choices, dance floors reacted in kind: first, with the crossover of indie and electro that swept through clubs in the early and mid-'00s—from Trash in London to Bang Gang in Sydney— then, with nu rave, the loud, gaudy era that embraced "bad taste" and made it cool.`,
          ],
          [
            `It was on MySpace, the nerve centre of the movement, that she came across Justice's era-defining 2007 album, <Cross>, before disappearing into an Ed Banger-shaped black hole, discovering the label's founder Busy P, Jackson and His Computerband, Uffie and the late DJ Mehdi. Charli has previously [[link:https://www.instagram.com/p/C5A1E4RsgAj/], [text: spoken]] about how Uffie's "Pop the Glock"—a venerated hit and one of the first tracks to achieve grassroots virality during this time—was a pivotal moment for her, saying, "I fell in love with music, production and partying, all at once, from my computer screen." When we spoke, she expanded on this, explaining how she was interested in how artists like Uffie, [[link:https://ra.co/features/4309], [text: Peaches]] and SebastiAn were "chopping vocals, using them not only as an instrument but as rhythm and part of the percussion. It made me want to live in that world."`,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `This world was one inhabited by mischievous art kids with a taste for bloghouse and electro, attending storied parties like Bugged Out! and Maison Kitsuné. In Charli's first unreleased mixtape, <14>—her age at the time—her influences were hardcoded into her choice of song titles, such as "Machines," "Neon Fashion and Glowstix" and the enthusiastic punctuation on "!Franchesckaar!" The (sort of) liner notes, which are still [[link:https://www.discogs.com/release/4486889-Charli-XCX-14/image/SW1hZ2U6ODk3MjQ3Mw==], [text: online]], also signalled to the artists she was inspired by: "Hadouken!, Kate Nash, Justice, Calvin Harris."`,
          ],
        ],
      },
    },

    {
      type: "TextMessages",
      background: {
        variant: "video",
        source: "Phone_01.mp4",
        poster: "Charli_01.jpg",
      },
      messages: [
        {
          type: "text",
          side: "right",
          from: "KT",
          text: "The (sort of) liner notes, which are still online, also signalled to the artists she was inspired by:",
          emoji: "🖥️",
        },
        {
          type: "text",
          side: "left",
          from: "XCX",
          text: "My influences at the moment are:",
          emoji: "😈",
        },
        {
          type: "text",
          side: "left",
          from: "KT",
          text: "Hadouken!",
          emoji: "😍",
        },
        {
          type: "text",
          side: "left",
          from: "XCX",
          text: "Kate Nash",
          emoji: "🖤",
        },
        {
          type: "text",
          side: "left",
          from: "XCX",
          text: "Justice",
          emoji: "✝️",
        },
        {
          type: "text",
          side: "left",
          from: "XCX",
          text: "Calvin Harris",
          emoji: "😎",
        },
        {
          type: "image",
          side: "right",
          from: "KT",
          source: "Charli_02.png",
          emoji: "💋",
        },
      ],
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [
          [
            `Charli was also 14 when she played her first warehouse shows in East London. Until then, she'd been limited to sitting on her PC and uploading bedroom tracks to MySpace, so a rave in a former factory sent her creative neurons into overdrive. "You know when you're younger," she wondered, "and something is so important and mind-blowing that in your memory you amp it up and remember it as more excessive than it was? Maybe that's how I'm remembering those days, but it was really eye-opening."`,
          ],
          [
            `One core memory took place at Peanut Factory in Hackney Wick. On the night she played, the main room had a small, makeshift stage occupied by a performance art group dressed as zebras. Parties like these usually involved DJ sets and eccentric live acts like The Coolness or Le Couteau Jaune, who, she said, "were doing something interesting and very abstract, with childlike lyrics in that classic nu rave way." At another show, she performed on a piece of plywood balancing precariously on two bins. "It was pretty grimy overall," she laughed. "But <good>."`,
          ],
          [
            `Those parties, and her love affair with French electronic music, formed the foundations of <BRAT>. "We had this instinct to go to the music that influenced [Charli] as a teenager, referencing Ed Banger, Peaches and that whole world," <BRAT>'s co-producer A. G. Cook said on a video call from Los Angeles. "That immediately started to feel like the spirit of what would become the album."`,
          ],
          [
            `But <BRAT> is hardly Charli's first record underpinned by dance music. Take 2013's "[[link:https://youtube.com/watch?v=qtrHH1BDlzk], [text: You (Ha Ha Ha),]]" which samples Gold Panda; or her dream collaboration with Uffie on "[[link:https://www.youtube.com/watch?v=nBHL2eW0yJU], [text: Babygirl,]]" in 2017; or "[[link:https://www.youtube.com/watch?v=XRza3wDl9pM], [text: visions,]]" the final track on 2020 lockdown album, <how i'm feeling now>, that ends in a cacophony of synths and distorted drums you'd find in the best happy hardcore tunes. So why finally claim club culture on <BRAT>, when Charli has existed in this space for most of her life?`,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `"This is hard to define, but it's important," Cook explained. "The amount of DJs who are using Charli's music, a capellas, stems—from small club nights to big pseudo-EDM festivals—the whole range has her fingerprints on it. And it's a lot of the music that SOPHIE contributed to, and the massive influence she had on electronic music producers. It's this extended world that's in the spirit of [<BRAT>], but also sometimes literally in the vocal chops or drum sounds."`,
          ],
          [
            `Doss, who played the warm-up set at Boiler Room in New York, said: "Specifically for queer DJs and queer spaces, Charli has been a staple for years—edits, remixes, straight-up tracks, they've always been played. It's not new. She's always been right there." Accordingly, the Angels are often more attuned to queer online slang than many other pop fandoms, and their dedication to Charli's art (such as that of popular Angel [[link:https://x.com/skyferrori], [text: skyferrori]], AKA Leon Alpuche) has helped propel <BRAT>'s wildfire organic success.`
          ],
          [
            `The inaugural PARTYGIRL, which left a whopping 36,902 Boiler Room [[link:https://www.instagram.com/p/C3vwCh7Pw4A/?img_index=1], [text: RSVPs]] without an invite, was the first seismic win in <BRAT>'s meticulous rollout. The first murmurs of the album began in 2022, around the release of <CRASH>, which was a meta exercise in using the major-label machine to create a commercial record fit for a stereotypical pop star. But by playing in the <CRASH> simulation, she fell prey to debilitating writer's block, far beyond the creative fatigue she'd typically anticipate at the end of an album run. The concept and vision for <BRAT> had already taken shape in her mind by then, but where she'd usually be charging ahead to the next chapter, the music wasn't following.`,
          ],
        ],
      },
    },

    {
      type: "PullQuote",
      cardSource: "Charli_01.jpg",
      background: {
        variant: "video",
        source: "VV_03_CROP.mp4",
        poster: "Charli_01.jpg",
      },
      text: `"The amount of DJs who are using Charli's music, a capellas, stems—from small club nights to big pseudo-EDM festivals—the whole range has her fingerprints on it."`,
      credit: "–A. G. Cook",
      textColor: "#FFFFFF",
      backgroundColor: "transparent",
    },


    {
      type: "LeftRightText",
      background: false,
      left: {
        type: "small-text",
        text: [
          [
            `"The problem was that I was treating [<BRAT>] as a character," she explained. "That was preventing me from being able to make music because <CRASH> was [also] a character. In a way, it felt like a repetition of something, which I really don't like to do."`
          ],
          [
            `Instead, Charli works best riding the "pendulum swing." If one project leans all the way in one direction, then the next needs to do the opposite. "Cosplaying as a major label artist" for <CRASH> meant her next move had to be 100 percent true to herself. And so she returned to the person she feels most comfortable with in the studio: Cook.`
          ],
          [
            `The pair first worked on "Girl, so confusing," a squelchy, Auto-Tuned synth pop missive that addresses the competitive relationship she has with another woman artist. (Don't worry—this has since been worked out on the [[link:https://www.youtube.com/watch?v=0q3K6FPzY18], [text: remix]] with Lorde, sending the Angels into complete hysteria.) Being back in the studio with Cook, and realising she needed to lean into more diaristic songwriting, allowed Charli's creativity to bloom. "I had to be absolutely <me>, lyrically," she said. "Challenging myself to be as stripped back as possible in terms of offering myself to the public. It unlocked this honesty within the music."`,
          ],
          [
            `One of many examples of this is <BRAT>'s bolshy lead single, "Von dutch," produced with PC Music affiliate Easyfun. Over raging synths that recall Bodyrox's 2006 hit "[[link:https://www.youtube.com/watch?v=fAxFgzTXluI], [text: Yeah Yeah]]," she turns the dial up to full-throttle and snaps into peak brat mode: "It's OK to just admit that you're jealous of me." This unruly energy is balanced out by softer, more vulnerable tracks like "I might say something stupid," in which she confronts imposter syndrome as someone who feels "famous but not quite." Perhaps the most dominant theme of all is the thrill of new love, inspired by her relationship with fiancé George Daniel (The 1975's drummer and principal producer), who she gushes over on "Talk talk" and "Everything is romantic."`,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `<BRAT>'s sonic palette reflects its colourful cast of producers: Cook and Easyfun, plus Daniel, Hudson Mohawke, Ed Banger affiliate Gesaffelstein, El Guincho, former scratch DJ champion Cirkut and electroclash revivalist The Dare. "Club classics," in which Charli sings about only wanting to listen to tunes made by her and her mates, is pure bubblegum bassline whomp. On the distinctive stomp of Gesaffelstein collaboration "B2b," the acid bassline mirrors the turbulence of a toxic on-off situationship. "Everything is romantic," on the other hand, floats in with a fairytale string arrangement that quickly gets the Charli treatment: pounding bass kicks over lyrics relaying a hazy summer in love.`,
          ],
          [
            `"It's a very uncompromised process," Daniel, who has credits on "Club classics" and "Apple," explained of his studio dynamic with Charli. "People forget that you need to make the record that you love more than anyone else in the world. She really stuck to that."`,
          ],
          [
            `This dedication to making the music <she> loves defines Charli's creative output. She credits this virtue to the [[link:https://ra.co/features/3832], [text: late Glasgow visionary SOPHIE]]. "So I," written with Easyfun, is deeply personal to both their relationships with the pioneering musician, who tragically passed away in 2021. Over gossamer keys, Charli interpolates the mesmerising synth vortex of SOPHIE's "It's Okay to Cry," her voice tactile as she reflects on her friend's advice: "I know you always said, 'It's okay to cry' / So I know I can cry, I can cry / So I cry." It's a gorgeous, heart-rending tribute that wrestles with the complexities of grief. "With someone like SOPHIE, you're so in awe of them and inspired by them," Easyfun said. "But you're also intimidated because they feel so superhuman. 'So I' is about that tension."`,
          ],
        ],
      },
    },

    // {
    //   type: "LeftRightText",
    //   background: false,
    //   textOnly: true,
    //   left: {
    //     type: "small-text",
    //     text: [[``]],
    //   },
    //   right: {
    //     type: "large-text",
    //     text: [
    //       [
    //         `"People forget that you need to make the record that you love more than anyone else in the world. She really stuck to that."`,
    //       ],
    //     ],
    //   },
    // },

    // {
    //   type: "LeftRight",
    //   background: false,
    //   textOnly: true,
    //   left: {
    //     type: "small-text",
    //     text: [[``]],
    //   },
    //   right: {
    //     type: "small-text",
    //     text: [
    //       [
    //         `This dedication to making the music <she> loves defines Charli's creative output. She credits this virtue to the [[link:https://ra.co/features/3832], [text: late Glasgow visionary SOPHIE]]. "So I," written with Easyfun, is deeply personal to both their relationships with the pioneering musician, who tragically passed away in 2021. Over gossamer keys, Charli interpolates the mesmerising synth vortex of SOPHIE's "It's Okay to Cry," her voice tactile as she reflects on her friend's advice: "I know you always said, 'It's okay to cry' / So I know I can cry, I can cry / So I cry." It's a gorgeous, heart-rending tribute that wrestles with the complexities of grief. "With someone like SOPHIE, you're so in awe of them and inspired by them," Easyfun said. "But you're also intimidated because they feel so superhuman. 'So I' is about that tension."`,
    //       ],
    //     ],
    //   },
    // },

    {
      type: "PullQuote",
      cardSource: "Charli_01.jpg",
      background: {
        variant: "video",
        source: "360_02.mp4",
        poster: "Charli_01.jpg",
      },
      text: `SOPHIE always advocated for Charli to use her authentic voice, and BRAT is as genuine as it gets.`,
      credit: "",
      textColor: "#000000",
      backgroundColor: "#FFFFFF",
    },

    {
      type: "LeftRight",
      background: false,
      left: {
        type: "small-text",
        text: [
          [
            `Charli and SOPHIE [[link:https://ra.co/exchange/753], [text: met]] for the first time in 2015, at a writing camp in Sweden. "It was a total sham," Charli laughed. "We basically just made songs that would never get pitched or go anywhere. We just partied all the time." Despite this, the camp dramatically re-routed Charli's musical trajectory. On the day SOPHIE arrived, the first track they made together was the initially maligned dance floor-buster "[[link:https://www.youtube.com/watch?v=qfAqtFuGjWM&ab_channel=VroomVroomRecordings], [text: Vroom Vroom]]." It's now a cult classic that grinds, crashes, twists and slides through three minutes of boggling electronics and instantly iconic lyrics: "Cute, sexy and my ride sporty." Played by DJs from Mary Anne Hobbs to Skream & Benga (who also delivered a rowdy remix of "[[link:https://www.youtube.com/watch?v=d1JgpgqeKis], [text: Von dutch]]" back in April), it was a divisive but bona fide club anthem—just how Charli and SOPHIE like it—and the extent to which modern pop has bent toward its devil-may-care euphoria proves just how influential the track's template has become.`,
          ],
          [
            `Working with SOPHIE was the first time Charli felt another artist speaking her language. Together they made the bold, unyielding and club-bound music Charli had been dreaming of since she was 14. "I'd been trying for so long to explain to people what I wanted but they couldn't get it," she said. "And SOPHIE totally could. She was so fearless when she made music. She didn't make compromises, ever."`,
          ],
          [
            `Does Charli hear any echoes of SOPHIE in <BRAT>? "There are probably some SOPHIE sounds—like <actual> SOPHIE Splice pack sounds going on in there," she laughed. Then she paused, growing contemplative. One of SOPHIE's "big things" was encouraging Charli to sing without Auto-Tune, sometimes going as far as pretending the software was broken when they worked together. On tracks like "Club classics" and "I think about it all the time," Charli honours SOPHIE's wishes and keeps her vocal bare. SOPHIE always advocated for Charli to use her authentic voice, and <BRAT> is as genuine as it gets.`,
          ],
        ],
      },
      right: {
        type: "image",
        source: "Charli_09.png",
      },
    },

    // {
    //   type: "LeftRightText",
    //   background: false,
    //   textOnly: true,
    //   left: {
    //     type: "small-text",
    //     text: [[``]],
    //   },
    //   right: {
    //     type: "small-text",
    //     text: [
    //       [
    //         `The question of authenticity came up when I spoke to Hudson Mohawke on a video call from New York. He praised Charli for `,
    //       ],
    //     ],
    //   },
    // },

    // {
    //   type: "LeftRightText",
    //   background: false,
    //   textOnly: true,
    //   left: {
    //     type: "small-text",
    //     text: [[``]],
    //   },
    //   right: {
    //     type: "large-text",
    //     text: [
    //       [
    //         `"creating this [culture] around herself, by herself. It's all out of her own curiosity and wanting to be in the mix. It's instinctive. To me, that's a Madonna thing, or an early George Michael thing." `,
    //       ],
    //     ],
    //   },
    // },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `The question of authenticity came up when I spoke to Hudson Mohawke on a video call from New York. He praised Charli for "creating this [culture] around herself, by herself. It's all out of her own curiosity and wanting to be in the mix. It's instinctive. To me, that's a Madonna thing, or an early George Michael thing."`
          ],
          [
            `Then, the Glaswegian artist reached for an unlikely equivalence: Jeff Mills. "That's a bit of a strange comparison," he acknowledged with a smile. And yet, somehow, it makes sense: the greatest musical disruptors aren't afraid to double down on their vision, persevere and play the music they want to play. They're the main characters in worlds of their own making. "It's vitally important that people are upsetting the applecart," he added. "Charli's had great success with this idea that you can have a production value on a song that rivals any headsy electronic record, and it can still be a big pop hit."`,
          ],
          [
            `<BRAT> is the first time Hudson Mohawke, Cook and Charli have worked together. You might have expected the pounding wobble of "Club classics" to be a HudMo special, but he's been experimenting with a more lightweight French touch sound. The result is "Talk talk," a euphoric cut with breathy high notes that captures the tangle of nerves at the start of a romantic connection.`
          ]
        ],
      },
    },

    // {
    //   type: "LeftRightText",
    //   background: false,
    //   textOnly: true,
    //   left: {
    //     type: "small-text",
    //     text: [[``]],
    //   },
    //   right: {
    //     type: "large-text",
    //     text: [
    //       [
    //         ` "Charli's had great success with this idea that you can have a production value on a song that rivals any headsy electronic record, and it can still be a big pop hit."`,
    //       ],
    //     ],
    //   },
    // },

    {
      type: "LeftRight",
      background: false,
      left: {
        type: "small-text",
        text: [
          [
            `Hudson Mohawke's second contribution to <BRAT> is "Mean girls," in which Charli paints a vivid image of a specific kind of chaotic girl (selfies with the flash on, last night's make-up, skinny cigarettes). The track moves from brassy EDM synths to a rippling piano interlude, before blooming into a jazzy house section, all in barely three minutes. At the time, the trio had been listening to tracks like The Joubert Singers' "[[link:https://www.youtube.com/watch?v=x9UV58U9DyU], [text: Stand on the Word]]," thinking about world-building within a song before wrongfooting listeners with a drastic pivot. "Now, in particular, everybody has a five-second attention span," Hudson Mohawke said. "It's nice to be like, 'Oh! We're here, and now we're going <there>.'"`,
          ],
          [
            `As the <BRAT> campaign has shown, Charli has attention-grabbing down to a fine art. The second time we spoke, we met in a quiet corner of her Barcelona hotel ahead of her headline show at Primavera Sound, and Charli dissected what she and Cook call the <BRAT>-osphere. In this setting, her demeanour was less brat, more cool and collected—the calm before the storm. Her crimped hair was slicked back, and when she arrived, she took off her signature wrap-around sunglasses and flashed a warm smile. The PARTYGIRL events, she went on to explain, have been key to <BRAT>'s world-building—it's all part of embedding the record deeper into club culture. "The songs thrive equally well in [that atmosphere]," she said. "And in some cases, even more so, because they take on this feral version of themselves."`,
          ],
          [
            `Fans had been teased with these "feral" <BRAT> edits, remixes and mash-ups for months before release day, via sporadic pop-ups and Charli's social media. Almost every track in the 75-minute [[link:https://boilerroom.tv/recording/charli-xcx24], [text: Boiler Room show]] (which included sets from Charli, Cook, Daniel and Easyfun) was an edit or remix, taking the crowd on a rollercoaster of big, broken basslines fuelled by helium vocals and iridescent trance synths. "I don't think there's anything more club-like than that, where someone's waiting for the edit, VIP or remix," Cook said.`,
          ],
        ],
      },
      right: {
        type: "image",
        source: "Charli_06.png",
      },
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "large-text",
        text: [
          [
            ` "She puts a lot of work into achieving that level of anarchy. It's all done with the air of a DJ, who's so <in> the music that it's all happening on the fly." –A. G. Cook`,
          ],
        ],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "small-text",
        text: [
          [
            `Being behind the decks is a new undertaking for Charli, and she's admittedly still nervous. You could divine as much watching her Boiler Room, but she told me straight up after PARTYGIRL in Barcelona. Her DJing borrows from Cook's "anti-DJ culture" approach—no headphones, minimal mixing, plenty of chaos—and Daniel's more methodical style. She happily occupies a middle ground, where smooth transitions converge with rewinds and abstract noise. Next up: a session at legendary Ibiza club Amnesia, joined by fellow party co-conspirators Shygirl, Kelly Lee Owens and Call Super.`,
          ],
          [
            `"I don't think there's many people with a fan base like mine—who want to go to spaces like that and actually party rather than stand around hoping I get on the mic," Charli said. `
          ]
        ],
      },
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [[``]],
      },
      right: {
        type: "large-text",
        text: [
          [
            `"My fans want to rave, let loose and really immerse themselves in this record."`,
          ],
        ],
      },
    },

    {
      type: "LeftRight",
      background: false,
      left: {
        type: "image",
        source: "Charli_07.png",
      },
      right: {
        type: "small-text",
        text: [
          [
            `(They're keen to study, too: "We research every fact and origin of Charli's music," [[link:https://x.com/skyferrori], [text: Alpuche]] told me. "We want to understand where the music is coming from.")`,
          ],
          [
            `Go to any Charli xcx performance and this commitment is refreshingly obvious. After she announced the surprise PARTYGIRL show in Barcelona, a crowd of at least 500 fans assembled in record time, some even clambering on bins to get a better view of Charli, Cook and Daniel in front of a <BRAT>-green cube. Charli and Daniel went back-to-back, dropping fan-favourites like "Focus" and an edit of the Boys Noize-indebted <BRAT> closer "365." Cook played his bright single "Britpop," as well as his scream-inducing remix of "Von dutch" featuring Addison Rae. When it ended, Charli was escorted back to her car while being pursued by a stream of fans. Those who didn't follow peeled <BRAT> wallpaper off the cube to take home—<BRAT>-mania was in full effect.`,
          ],
          [
            `At 2:30 AM the next morning, the Angels were practically levitating for Charli's live show, as thousands of girls and gays hurled themselves into the air, bellowing every word to every song. She stomped around the stage with <BRAT>-green streaks in her hair, imploring us to "get the fuck up" while white spotlights blazed across the crowd like strobes. She opened with a raucous techno edit of "365," everyone roaring as a green curtain fell from the top of the stage behind her. A few minutes later, as her and Daniel's fidget house remix of Caroline Polachek's "Welcome to My Island" sent the crowd wilder still, she grabbed the curtain and appeared to pull it to the ground with her own superstar strength. `,
          ],
          [
            `Charli later referred to the show as "[[link:https://www.instagram.com/p/C7ucwVKP5nx/?img_index=1], [text: GOD TIER]]." One Angel on X took a look at an [[link:https://x.com/dxckiii/status/1797127960853385688], [text: image]] of her commanding stance in front of the <BRAT> curtain and wrote: "I'd like to imagine George Washington posing like this after signing the Declaration."`,
          ],
        ],
      },
    },

    {
      type: "FullBleed",
      variant: "video",
      source: "VD_02.mp4",
      poster: "Charli_09.png",
    },

    {
      type: "LeftRightText",
      background: false,
      textOnly: true,
      left: {
        type: "small-text",
        text: [
          [
            `Therein lies the irresistible appeal of Charli xcx. Like the Angels, she's witty, referential and hyper-aware of her influence as a leader. She knows that today's generation of clubbers is curious to understand and honour musical lineage, yet, like Charli herself, tends to reject the idea of rigidity or monoculture. Instead, they crave dance floors that indulge prime-time pop alongside experimental electronic music. In these judgement-free spaces, Britney Spears hits just as hard as Aphex Twin. And who better to lead the charge for musical adventurousness and freedom than a pop star who rose from the underground, with co-signs from Robyn to Arca; a real person who lives, breathes and endorses this life?`,
          ],
          [
            `Perhaps some <RA> readers might have started this story thinking that Charli xcx is <too pop> for them. What would Charli say to that, I asked? "Oh, I'm sure there are many of them reading this—hello!" she cackled. Then, with a glint in her eye, she continued. "I'd say, if you think that I'm not supposed to be in your world, then do your fucking research. Get reading, basically. And also—I don't really mind. You can never please everyone, and why would I want to? That's not what I'm here to do."`,
          ],
        ],
      },
      right: {
        type: "small-text",
        text: [[``]],
      },
    },

    { type: "YoutubeEmbed" },
    // { type: "Spotify" },
  ],
};

export default Data;
