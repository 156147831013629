import { useState, useEffect, useRef } from "react";
import TextMessage from "./TextMessage";

const TextMessages = (props) => {
  const { data } = props;

  const [ messageIndex, setMessageIndex ] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [done, setDone] = useState(false);
  const elementRef = useRef(null);
  const speed = 400;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (messageIndex < data.messages.length && isInView) {
      const timeoutId = setTimeout(() => {
        setMessageIndex(prevIndex => prevIndex += 1);
      }, speed);

      return () => clearTimeout(timeoutId);
    } else if (messageIndex === data.messages.length) {
      setTimeout(() => {
        setDone(true);
      }, 800);
    }
  }, [messageIndex, data.messages, speed, isInView]);

  return (
    <section className="texts__wrapper" ref={elementRef}>
      <div className="texts__background-wrapper">
        {data.background && data.background.variant === "image" ? (
          <div
            className="texts__background-image"
            style={{
              backgroundImage: `url(./images/${data.background.source})`,
            }}
          />
        ) : (
          <div className="texts__background-video-wrapper">
            <video
              className="texts__background-video"
              src={`./videos/${data.background.source}`}
              autoPlay
              loop
              muted
              playsInline
              poster={`url(./images/${data.background.poster})`}
            />
          </div>
        )}
      </div>

      <div className="texts__messages-wrapper">
        {data.messages.map((message, index) => {
          return (
           <TextMessage {...{message, index, messageIndex}} key={index}/>
          );
        })}
      </div>
    </section>
  );
};

export default TextMessages;
