import React, { useEffect, useState } from 'react'

const ReactEmoji = (props) => {

    const { active, emoji } = props;
    const [rotation, setRotation] = useState(Math.random() * 360);
    const [animationTime] = useState(Math.random() * 1 + 1);
    const [left , setLeft] = useState(Math.random() * window.innerWidth);
    const [top , setTop] = useState(100);

    useEffect(()=>{
        setRotation(Math.random() * 360);
        setLeft(Math.random() * window.innerWidth);
        setTop(-10);
        setTimeout(()=>{
            setTop(110);
        }, 2000)
    },[active])

  return (
    <div className={`texts__react-emoji ${active?'active':''}`}
        style={{
            transition: active?`all ${animationTime}s ease-in-out`:'',
            transform: `rotate(${rotation}deg)`,
            left: `${left}px`,
            top: `${top}%`,
        }}
    >{emoji}</div>
  )
}

export default ReactEmoji