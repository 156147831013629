import { useState, useEffect, useRef } from "react";

const PullQuote = (props) => {
  const { data } = props;

  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [done, setDone] = useState(false);
  const elementRef = useRef(null);
  const speed = 10;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (index < data.text.length && isInView) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(displayedText + data.text.charAt(index));
        setIndex(index + 1);
      }, speed);

      return () => clearTimeout(timeoutId);
    } else if (index === data.text.length) {
      setTimeout(() => {
        setDone(true);
      }, 800);
    }
  }, [index, data.text, displayedText, speed, isInView]);

  return (
    <section className="pull-quote__wrapper" ref={elementRef}>
      <div className="pull-quote__background-wrapper">
        {data.background && data.background.variant === "image" ? (
          <div
            className="pull-quote__background-image"
            style={{
              backgroundImage: `url(./images/${data.background.source})`,
            }}
          />
        ) : (
          <div className="pull-quote__background-video-wrapper">
            <video
              className="pull-quote__background-video"
              src={`./videos/${data.background.source}`}
              autoPlay
              loop
              muted
              playsInline
              poster={`url(./images/${data.background.poster})`}
            />
          </div>
        )}
      </div>

      <div
        className={`pull-quote__card-wrapper alt-font ${
          data.cardSource2 ? "flip" : ""
        }`}
      >
        <div
          className="pull-quote__card-front"
          style={{
            color: data.textColor,
            backgroundColor: data.backgroundColor,
            backgroundImage: `url(./images/${data.cardSource2})`,
          }}
        >
          <h3>
            {displayedText}{" "}
            <span className={`pull-quote__cursor ${done ? "disabled" : ""}`}>
              {" |"}
            </span>
          </h3>
          {!data.cardSource2 ? (
            <div className="pull-quote__credit">{data.credit}</div>
          ) : (
            ""
          )}
        </div>
        <div
          className="pull-quote__card-back"
          style={{
            backgroundImage: `url(./images/${data.cardSource})`,
          }}
        ></div>
      </div>
    </section>
  );
};

export default PullQuote;
