import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Data from "./assets/Data";

import Intro from "./components/Intro";
import StandFirst from "./components/StandFirst";
import LeftRight from "./components/LeftRight";
import LeftRightText from "./components/LeftRightText";
import FullBleed from "./components/FullBleed";
import PullQuote from "./components/PullQuote";
import Spotify from "./components/Spotify";
import ReadTime from "./components/ReadTime";
import TextMessages from "./components/TextMessages";
import Cover from "./components/Cover";
import Carousel from "./components/Carousel";

import "./styles/global-styles.scss";
import YoutubeEmbed from "./components/YoutubeEmbed";


function App() {
  const [pageData, setPageData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isReady, setIsReady] = useState(false);

  // const [pointerEvents, setPointerEvents] = useState('none')

  if (!isReady) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    if (Data) {
      setPageData(Data);
    }
  }, [Data]);

  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    const checkScreenWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", setAppHeight);
    window.addEventListener("resize", checkScreenWidth);

    setAppHeight();
    checkScreenWidth();

    return () => {
      window.removeEventListener("resize", setAppHeight);
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const components = {
    StandFirst,
    LeftRight,
    LeftRightText,
    FullBleed,
    PullQuote,
    TextMessages,
    Cover,
    Carousel,
    Spotify,
    YoutubeEmbed
  };



{/* <style>#feature #feature-content, #microsite #feature-content{max-width: none !important;padding: 0 !important;margin: 0 !important;} #CharliArticle{ pointer-events: none} #CharliArticle.active{pointer-events: all} #feature header{display:none;}</style><script>const wrapper = document.getElementById('CharliArticle');const checkTop = () => {const rect = wrapper.getBoundingClientRect(); if(rect.top <= 0){wrapper.classList.add('active')}else{wrapper.classList.remove('active')}}; window.addEventListener('scroll', checkTop);</script><div id="CharliArticle" style="height:400vh;"><iframe src="https://ra-charli-xcx.vercel.app/" title="Charli XCX Feature" style=" width: 100vw; height: 100vh; position: sticky; top: 0;left: 0px; overflow-x:hidden; box-shadow: none; border: none;"></iframe><div> */}


  if (pageData) {
    return (
        <article style={{width: 'calc(100vw)'}}>
          
          <div className={`global__blocker ${isReady?'disabled':''}`}>
            <button className="global__start-button" onClick={()=>setIsReady(true)}>BEGIN</button>
          </div>

          <ReadTime />
          <main>
            <Intro pageData={pageData} />
            {pageData.content &&
              pageData.content.map((data, index) => {
                const Component = components[data.type];
                return (
                  <Component data={data} key={index} isMobile={isMobile} />
                );
              })}
              {/* <Spotify /> */}
              <Cover data={pageData.cover}/>
          </main>
        </article>
    );
  } 
}

export default App;


// To prevent a page from scrolling until a button is pressed in a React component, you can achieve this by setting the "overflow" property of the body to "hidden" until the button is clicked. Here's an example of how you can implement this:

// jsx

// import React, { useState } from "react";

// const ScrollPreventComponent = () => {
//   const [preventScroll, setPreventScroll] = useState(true);

//   const handleButtonClick = () => {
//     setPreventScroll(false);
//   };

//   if (preventScroll) {
//     document.body.style.overflow = "hidden";
//   } else {
//     document.body.style.overflow = "auto";
//   }

//   return (
//     <div>
//       <button onClick={handleButtonClick}>Enable Scrolling</button>
//       <div>
//         {/* Your content goes here */}
//       </div>
//     </div>
//   );
// };

// export default ScrollPreventComponent;
// Please note that directly manipulating the DOM like this is not a common practice in React, but it can be used for specific use cases like this one.


// Regenerate Response