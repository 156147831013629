const FullBleed = (props) => {
  const { data } = props;

  if (data.variant === "image") {
    return (
      <section
        className="full-bleed__wrapper"
        style={{
          backgroundImage: `url(./images/${data.source})`,
        }}
      />
    );
  } else {
    return (
      <section className="full-bleed__wrapper">
        <video
          className="full-bleed__video"
          src={`./videos/${data.source}`}
          autoPlay
          loop
          muted
          playsInline
          poster={`url(./images/${data.poster})`}
        />
      </section>
    );
  }
};

export default FullBleed;
