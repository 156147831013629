import React from "react";

const Spotify = (props) => {
  const { isMobile } = props;

  return (
    <aside className="spotify__wrapper">
      <iframe
        style={{borderRadius:'12px', margin: '30px auto'}}
        src="https://open.spotify.com/embed/album/2lIZef4lzdvZkiiCzvPKj7?utm_source=generator"
        width={!isMobile?"40%":"calc(100% - 40px)"}
        height="352"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </aside>
  );
};

export default Spotify;
