import React from "react";

const AnimatedLine = (props) => {
  const { stroke } = props;

  return (
    <div className="al__wrapper">
      <svg width="100%" height="100%" className="al__animated-line-one">
        <line x1="0" y1="100%" x2="100%" y2="0" stroke={stroke} />
      </svg>
      <svg width="100%" height="100%" className="al__animated-line-two">
        <line x1="100%" y1="0" x2="0" y2="100%" stroke={stroke} />
      </svg>
    </div>
  );
};

export default AnimatedLine;
