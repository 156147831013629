import React from "react";

const Cover = (props) => {
  const { data } = props;

  return (
    <section className="cover__wrapper">
      <div className="cover__background-wrapper">
        {data.background && data.background.variant === "image" ? (
          <div
            className="cover__background-image"
            style={{
              backgroundImage: `url(./images/${data.background.source})`,
            }}
          />
        ) : (
          <div className="cover__background-video-wrapper">
            <video
              className="cover__background-video"
              src={`./videos/${data.background.source}`}
              autoPlay
              loop
              muted
              playsInline
              poster={`url(./images/${data.background.poster})`}
            />
          </div>
        )}
      </div>

      <video
        className="cover__video"
        src={`./videos/${data.source}`}
        autoPlay
        loop
        muted
        playsInline
        poster={`url(./images/${data.poster})`}
      />

      {/* <img src={`./images/${data.source}`} alt="CHARLI XCX" className="cover__image"/> */}
    </section>
  );
};

export default Cover;
